import Target from "@/components/Target";
import { HeaderContext } from "@/contexts/HeaderContext";
import { NavigationContext } from "@/contexts/NavigationContext";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import colourways from "@/lib/colourways";
import clsx from "clsx";
import { NotificationsContext } from "contexts/NotificationsContext";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { X } from "react-feather";
import useSWR from "swr";

const excludedPaths = [
  "/login",
  "/signin",
  "/signup",
  "/premium",
  // "/premium/dashboard",
];

const Notifications = ({ initialNotifications = [] }) => {
  const router = useRouter();
  const { plan } = useSubscriptionPermission(null);

  const { isDesktopNavigationActive } = useContext(NavigationContext);
  const { isTop, isPinned } = useContext(HeaderContext);

  const {
    showNotification,
    setShowNotification,
    setDismissedNotificationIds,
    dismissedNotificationIds,
  } = useContext(NotificationsContext);

  const [activeNotification, setActiveNotification] = useState(null);

  const handleDismiss = () => {
    // Add the id of the notification to the dismissedNotificationIds array
    setDismissedNotificationIds([
      ...dismissedNotificationIds,
      activeNotification.id,
    ]);
  };

  // We use the provided notification, and refresh when needed
  const {
    data: notifications,
    error,
    isLoading,
  } = useSWR("/api/notifications", {
    fallbackData: initialNotifications,
    revalidateOnMount: false,
    revalidateOnFocus: false,
  });
  // Determine which notification we should show
  useEffect(() => {
    const notificationPlan = plan ?? 0;

    // If there are no notifications, don't do anything
    if (!notifications || notifications.length === 0) return;

    // Find the first non-dismissed notification that matches our user group
    const notification = notifications.find(
      (notification) =>
        !dismissedNotificationIds.includes(notification.id) &&
        notification.whoShouldSeeThis.includes(notificationPlan.toString())
    );

    if (!!notification && !excludedPaths.includes(router.pathname)) {
      setActiveNotification(notification);
      setShowNotification(true);
    } else {
      setActiveNotification(null);
      setShowNotification(false);
    }
  }, [
    showNotification,
    notifications,
    setActiveNotification,
    dismissedNotificationIds,
    setShowNotification,
    router.pathname,
    plan,
  ]);

  // Dismiss the notification when there's no more to show
  useEffect(() => {
    if (!activeNotification) {
      setShowNotification(false);
    }
  }, [activeNotification, setShowNotification]);

  // Get colourway
  const colourwayHandle = activeNotification?.colourway?.[0]?.colourwayHandle;

  const colourway = colourways(colourwayHandle || "default");

  const _NotificationContent = () => {
    return (
      <Target
        target={activeNotification.target}
        onClick={handleDismiss}
        className="px-3 text-sm lg:text-base"
      >
        {activeNotification.title}
      </Target>
    );
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--notification-height",
      showNotification ? "48px" : "0px"
    );
  }, [showNotification]);

  return (
    <div
      className={clsx(
        "Notifications",
        "fixed left-0 right-0 top-0 z-50 transform-gpu transition duration-300 ease-out"
      )}
    >
      <div
        className={clsx(
          "flex h-12 items-center justify-center font-spatial font-normal",
          `${colourway.bg}`,
          {
            "text-white": colourway.useLightText,
            "text-black": !colourway.useLightText,
          }
        )}
      >
        {!!activeNotification && (
          <>
            <div
              className={clsx(
                "relative flex h-full w-full items-center lg:hidden"
              )}
            >
              <Marquee play gradient={false} speed={40}>
                <_NotificationContent />
              </Marquee>
              <div
                className={clsx(
                  "absolute bottom-0 right-12 top-0 z-10 w-24 bg-gradient-to-r",
                  "pointer-events-none from-transparent",
                  `via-${colourwayHandle}-500 to-${colourwayHandle}-500`
                )}
              ></div>
              <div
                className={clsx(
                  "absolute bottom-0 right-0 top-0 z-10 w-12 bg-gradient-to-r",
                  "pointer-events-none from-transparent",
                  `bg-${colourwayHandle}-500`
                )}
              ></div>
            </div>
            <div className={clsx("hidden lg:block")}>
              <_NotificationContent />
            </div>
          </>
        )}
        <button
          className={clsx(
            "Notifications__dismiss",
            "absolute right-3 z-10 flex h-12 w-12 items-center justify-center transition duration-300 ease-out hover:opacity-50 lg:right-6"
          )}
          onClick={handleDismiss}
        >
          <X size={21} strokeWidth={2} className={clsx("")} />
        </button>
      </div>
    </div>
  );
};

export default Notifications;
