import SearchOverlayDesktop from "@/components/SearchOverlayDesktop";
import SearchOverlayMobile from "@/components/SearchOverlayMobile";
import { useScreenSizeContext } from "@/contexts/ScreenSizeContext";

const SearchOverlay = () => {
  const { isBreakpoint, breakpoint } = useScreenSizeContext();

  if (!breakpoint) return null;

  if (isBreakpoint("lg")) {
    return <SearchOverlayDesktop />;
  } else {
    return <SearchOverlayMobile />;
  }
};

export default SearchOverlay;
