export const footerNavigation = [
  {
    id: "aboutUs",
    label: "About Us",
    isLoggedIn: true,
    isLoggedOut: true,
    href: "/about",
  },
  {
    id: "contactUs",
    label: "Contact Us",
    isLoggedIn: true,
    isLoggedOut: true,
    href: "/contact-us",
  },

  {
    id: "cookies",
    label: "Privacy & Cookies",
    isLoggedIn: true,
    isLoggedOut: true,
    href: "/cookies",
  },

  {
    id: "faqs",
    label: "FAQs",
    isLoggedIn: true,
    isLoggedOut: true,
    href: "/faqs",
  },
  {
    id: "careers",
    label: "Careers",
    isLoggedIn: true,
    isLoggedOut: true,
    href: "/careers",
  },
  {
    key: "privacySettings",
    label: "Privacy Settings",
    isLoggedIn: true,
    isLoggedOut: true,
    href: "/privacy-settings",
  },
  {
    key: "mobPlusTC",
    label: "Mob Premium Terms and Conditions",
    isLoggedIn: true,
    isLoggedOut: false,
    href: "/mob-plus-terms-and-conditions",
  },

  /*
  {
    id: "felix",
    label: "The Felix Project",
    href: "/the-felix-project",
  },
  {
    id: "privacyPolicy",
    label: "Privacy Policy",
    href: "/privacy-policy-3",
  },
  {
    id: "competitionTCs",
    label: "Competition T&Cs",
    href: "/competition-t-cs",
  },
  {
    id: "complaintsProcedure",
    label: "Complaints Procedure",
    href: "/complaints-procedure",
  },
  {
    id: "privacySettings",
    label: "Privacy Settings",
    href: "#",
  },
	*/
];

export const accountNav = [
  /* { href: "/saved-recipes", label: "Saved Recipes" }, */
  /*{ href: "/planner", label: "My Planner", plan: 2 },*/
  /*{ href: "/collections", label: "My Collections" },*/
  { href: "/account", label: "Account Settings" },
  {
    href: process.env.NEXT_PUBLIC_STRIPE_BILLING_PORTAL_URL ?? "#",
    label: "Payments & Invoices",
  },
];
