import QuerySuggestions from "@/components/QuerySuggestions";
import SearchOverlayRefinements from "@/components/SearchOverlayRefinements";
import SearchOverlaySectionMenu from "@/components/SearchOverlaySectionMenu";
import { useSearchContext } from "@/contexts/SearchContext";
import cloudflareLoader from "@/lib/cloudflare-images";
import getSectionNameFromHandle from "@/lib/getSectionNameFromHandle";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePreventScroll } from "react-aria";
import { Hits, useInstantSearch } from "react-instantsearch";

const listClassName = clsx("flex flex-col mx-0 px-0 space-y-3");
const itemClassName = clsx("flex flex-row items-center space-x-3");
const itemImageContainerClassName = clsx("w-full max-w-[72px]");
const itemImageWrapperClassName = clsx(
  "relative aspect-square overflow-hidden rounded-sm text-zinc-50 bg-zinc-800 flex items-center justify-center"
);

const SearchOverlayMobile = () => {
  const router = useRouter();

  const { isMobileOverlayOpen, setIsMobileOverlayOpen } = useSearchContext();

  const handleOpenChange = (open: boolean) => {
    // setIsOverlayOpen(open);
  };

  // Using next router, when the route changes, close the overlay
  useEffect(() => {
    const handleRouteChange = () => {
      setIsMobileOverlayOpen(false);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events, setIsMobileOverlayOpen]);

  usePreventScroll({ isDisabled: !isMobileOverlayOpen });

  return (
    <>
      <Dialog.Root
        open={isMobileOverlayOpen}
        onOpenChange={handleOpenChange}
        modal={false}
      >
        <Dialog.Portal>
          <div
            className={clsx(
              "SearchOverlayMobile__overlay",
              "top-header-and-notification fixed inset-x-0 bottom-0 top-0 z-40 bg-zinc-950"
            )}
          />
          <Dialog.Content
            className={clsx(
              "SearchOverlayMobile__content",
              "top-header-and-notification fixed inset-x-0 bottom-0 z-50"
            )}
            onOpenAutoFocus={(event) => {
              event.preventDefault();
            }}
            onEscapeKeyDown={() => setIsMobileOverlayOpen(false)}
            onPointerDownOutside={(event) => {
              if (event.target.id !== "mobile-search-input") {
                setIsMobileOverlayOpen(false);
              }
            }}
          >
            <Dialog.Close
              className={clsx(
                "SearchOverlayMobile__close",
                "absolute right-0 top-0 w-16"
              )}
            />

            <_Content />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

const _Content = () => {
  const searchUrl = `/search`;

  return (
    <div className={clsx("h-full")}>
      <div
        className={clsx(
          "sticky top-0 z-20 flex w-full items-center bg-zinc-950 pb-4.5"
        )}
      >
        <div className={clsx("flex w-full flex-col")}>
          <div className={clsx("flex items-center pl-6")}>
            <div>
              <SearchOverlaySectionMenu />
            </div>
            <div className={clsx("overflow-hidden pl-4")}>
              <SearchOverlayRefinements isMobile />
            </div>
          </div>
        </div>
      </div>
      <div className={clsx("absolute inset-0 z-0 px-6")}>
        <ScrollArea.Root className={clsx("relative h-full overflow-hidden")}>
          <ScrollArea.Viewport className={clsx("h-full pb-28 pt-16")}>
            <div className={clsx("space-y-3")}>
              <_Suggestions />
              <_Hits />
            </div>
          </ScrollArea.Viewport>

          <ScrollArea.Scrollbar
            orientation="vertical"
            className={clsx(
              "group absolute bottom-0 right-0 flex w-3.5 touch-none select-none bg-transparent p-1 pb-4 pt-[8.5rem]"
            )}
          >
            <ScrollArea.Thumb
              className={clsx(
                "w-1.5 flex-1 rounded-full bg-zinc-700 transition-colors ease-out"
              )}
            />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>

      <div
        className={clsx(
          "fixed inset-x-0 bottom-0 flex items-center justify-center pb-12"
        )}
      >
        <Link href={searchUrl}>
          <a className={clsx("button button-light mx-auto")}>
            <div className={clsx("text-sm")}>All Results</div>
          </a>
        </Link>
      </div>
    </div>
  );
};

const _Hit = ({ hit, sendEvent }) => {
  const { setLastAlgoliaQueryId } = useSearchContext();

  const image = hit.image?.[0];
  const href = `/${hit.uri}`;

  const handleClick = () => {
    sendEvent("click", hit, "Recipe Clicked from Overlay");
    setLastAlgoliaQueryId(hit.__queryID);
  };

  return (
    <Link href={href}>
      <a onClick={handleClick} className={clsx(itemClassName)}>
        <div className={clsx(itemImageContainerClassName)}>
          <div className={clsx(itemImageWrapperClassName)}>
            {!!image && (
              <Image
                src={image.url}
                layout="fill"
                objectFit="cover"
                alt={image.title}
                loader={cloudflareLoader}
                sizes="72px"
              />
            )}
          </div>
        </div>
        <div className={clsx("")}>
          <div className={clsx("font-body text-xs text-zinc-400")}>
            {getSectionNameFromHandle(hit.sectionHandle)}
          </div>
          <div className={clsx("text-sm text-zinc-50")}>{hit.title}</div>
        </div>
      </a>
    </Link>
  );
};

const _Hits = () => {
  return (
    <Hits
      hitComponent={_Hit}
      classNames={{
        list: clsx("flex flex-col space-y-3.5"),
        item: clsx(""),
      }}
    />
  );
};

const _Suggestions = () => {
  const router = useRouter();

  const { setIndexUiState, indexUiState } = useInstantSearch();

  const { query } = indexUiState;

  const handleClick = (query) => {
    // Set the query
    setIndexUiState((prevIndexUiState) => ({
      ...prevIndexUiState,
      query,
    }));

    // Start pushing to the search results page
    router.push(`/search`);
  };

  if (!query) return null;

  return (
    <QuerySuggestions query={query} className={listClassName}>
      {(hits) =>
        hits
          .filter((h) => h.query !== query)
          .slice(0, 2)
          .map((hit) => (
            <button
              key={hit.objectID}
              onClick={() => handleClick(hit.query)}
              className={clsx(itemClassName)}
            >
              <div className={clsx(itemImageContainerClassName)}>
                <div className={clsx(itemImageWrapperClassName)}>
                  <MagnifyingGlassIcon
                    className={clsx("h-5 w-5 stroke-[1.5]")}
                  />
                </div>
              </div>
              <div className={clsx("text-sm text-zinc-50")}>{hit.query}</div>
            </button>
          ))
      }
    </QuerySuggestions>
  );
};

export default SearchOverlayMobile;
