import EntryThumbnailCompact from "@/components/EntryThumbnailCompact";
import Loading from "@/components/Loading";
import EmptyState from "@/lists/components/EmptyState";
import useList from "@/lists/hooks/useList";
import useLists from "@/lists/hooks/useLists";
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const List = ({ listUid, onClose = () => null }) => {
  const { list, isLoading, removeElement, remove } = useList({
    listUid,
  });

  const handleClick = () => {
    onClose();
  };

  const handleRemove = () => {
    if (window.confirm("Are you sure you want to delete this list?")) {
      remove();
      onClose();
    }
  };

  return (
    <div className={clsx("flex flex-col px-6 py-6 lg:px-12")}>
      {isLoading && (
        <div className={clsx("flex justify-center")}>
          <Loading />
        </div>
      )}

      {!isLoading && (
        <div
          className={clsx(
            "flex w-full items-center pb-6 text-zinc-50 lg:text-base"
          )}
        >
          <div className={clsx("flex items-center space-x-4")}>
            <button
              className={clsx(
                "h-component-sm w-component-sm flex items-center justify-center rounded-full bg-zinc-50 text-zinc-950"
              )}
              onClick={handleClick}
            >
              <ArrowLeftIcon className={clsx("h-4 w-4")} />
            </button>
            <div className={clsx("flex space-x-2 font-body text-sm")}>
              <div>{list.title}</div>
              <div className={clsx("text-zinc-500")}>
                {list.listUserElements?.length}{" "}
                {list.listUserElements?.length === 1 ? "recipe" : "recipes"}
              </div>
            </div>
          </div>
          <div className={clsx("ml-auto")}>
            <button
              className={clsx(
                "h-component-sm w-component-sm flex items-center justify-center rounded-full bg-zinc-700 transition ease-out hover:bg-zinc-600"
              )}
              aria-label="Delete list"
              onClick={handleRemove}
            >
              <TrashIcon className={clsx("h-4 w-4")} />
            </button>
          </div>
        </div>
      )}

      {!isLoading && list.listUserElements?.length === 0 && (
        <EmptyState>You haven't saved any recipes to this list yet</EmptyState>
      )}

      {!isLoading && list.listUserElements?.length > 0 && (
        <div
          className={clsx(
            "grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
          )}
        >
          {list.listUserElements?.map((listUserElement) => {
            return (
              <_ListUserElement
                key={listUserElement.uid}
                listUserElement={listUserElement}
                removeElement={removeElement}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const _ListUserElement = ({ listUserElement, removeElement }) => {
  const { listElementIds } = useLists();

  const element = listUserElement.userElement.element;

  // Map the element to the expected entry format
  const entry = {
    id: element.id,
    title: element.title,
    uri: element.uri,
    image: [element.image],
    sectionHandle: element.section.handle,
    limitAccessTo: element.limitAccessTo,
    premium: element.premium,
  };

  const handleRemove = async () => {
    await removeElement(element.id);
  };

  return (
    <div key={listUserElement.uid} className={clsx("col-span-1 text-zinc-50")}>
      <EntryThumbnailCompact
        entry={entry}
        showSection={false}
        showRemoveButton
        onRemove={handleRemove}
      />
    </div>
  );
};

export default List;
