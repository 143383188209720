import React, { useState } from "react";
export const HeaderContext = React.createContext();

export const HeaderContextProvider = ({ children }) => {
  // Determine if the header is at the top of the page or pinned
  const [isTop, setIsTop] = useState(true);
  const [isPinned, setIsPinned] = useState(false);

  const value = {
    isTop,
    isPinned,
    setIsTop,
    setIsPinned,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = () => React.useContext(HeaderContext);
