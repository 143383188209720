import { createContext, useContext, useState } from "react";

// Create the context with a default value
const MoodContext = createContext({
  isOpen: false,
  setIsOpen: (value: boolean) => null,
  selectedMood: null,
  setSelectedMood: (mood: any) => null,
});

// Create a provider component
export const MoodContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMood, setSelectedMood] = useState(null);

  return (
    <MoodContext.Provider
      value={{
        isOpen,
        setIsOpen,
        selectedMood,
        setSelectedMood,
      }}
    >
      {children}
    </MoodContext.Provider>
  );
};

// Create a custom hook to use the MoodContext
export const useMoodContext = () => {
  const context = useContext(MoodContext);

  if (context === undefined) {
    throw new Error("useMoodContext must be used within a MoodProvider");
  }

  return context;
};

export default MoodContextProvider;
