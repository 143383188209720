import { useEffect } from "react";
import useCurrentUser from "@/hooks/useCurrentUser";
import aa from "search-insights";

const useIntializeAlgoliaInsights = () => {
  const { currentUser, isLoggedOut } = useCurrentUser();

  useEffect(() => {
    aa("init", {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    });
  }, []);

  // Set the userToken on the algolia analytics object
  useEffect(() => {
    if (isLoggedOut) return;

    let interval;

    // Function to check if window.aa and currentUser.uid are available and then call window.aa with currentUser.uid
    const setUserToken = () => {
      if (typeof aa === "function" && currentUser?.uid) {
        aa("setUserToken", currentUser.uid);

        // Clear the interval once the token is set
        if (interval) {
          clearInterval(interval);
        }
      }
    };

    // Call the function
    setUserToken();

    // Set up an interval to check periodically if window.aa becomes available
    interval = setInterval(setUserToken, 1000);

    // Cleanup interval on component unmount
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isLoggedOut, currentUser?.uid]);
};

export default useIntializeAlgoliaInsights;
