import clsx from "clsx";

const CountIndicator = ({ count }) => {
  return (
    <div
      className={clsx(
        "absolute -right-1 -top-1 flex h-4 w-auto min-w-4 max-w-5 items-center justify-center overflow-hidden rounded-full bg-red-500 px-0.5 text-xxs tabular-nums leading-none"
      )}
    >
      {count > 9 ? "9+" : count}
    </div>
  );
};

export default CountIndicator;
