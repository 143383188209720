import recommendClient from "@/lib/algoliaRecommendClient";
import searchClient from "@/lib/algoliaSearchClient";
import qs from "qs";

export const searchStateToUrl = (searchState) =>
  `?query=${searchState.query}&page=${
    searchState.page
  }&refinementList=${JSON.stringify(searchState.refinementList || {})}`;

export const urlToSearchState = (url) => {
  const { query, page, refinementList } = qs.parse(url);
  return {
    query: query || "",
    page: page || 1,
    refinementList: refinementList ? JSON.parse(refinementList) : {},
  };
};

export const createSearchHref = (params) => {
  const baseURL = "/search";
  const indexName = process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME;
  let url = `${baseURL}?`;

  Object.keys(params).forEach((paramKey) => {
    const paramValue = params[paramKey];

    if (typeof paramValue === "object" && paramValue !== null) {
      Object.keys(paramValue).forEach((key) => {
        if (Array.isArray(paramValue[key])) {
          // Process each array element separately
          paramValue[key].forEach((value) => {
            const encodedKey = `${encodeURIComponent(
              indexName
            )}%5B${encodeURIComponent(paramKey)}%5D%5B${encodeURIComponent(
              key
            )}%5D[]`;
            const encodedValue = encodeURIComponent(value);
            url += `${encodedKey}=${encodedValue}&`;
          });
        } else {
          // Single object not in an array
          const encodedKey = `${encodeURIComponent(
            indexName
          )}%5B${encodeURIComponent(paramKey)}%5D%5B${encodeURIComponent(
            key
          )}%5D`;
          const encodedValue = encodeURIComponent(paramValue[key]);
          url += `${encodedKey}=${encodedValue}&`;
        }
      });
    } else {
      // Handle string-type parameters
      const encodedKey = `${encodeURIComponent(
        indexName
      )}%5B${encodeURIComponent(paramKey)}%5D`;
      const encodedValue = encodeURIComponent(paramValue);
      url += `${encodedKey}=${encodedValue}&`;
    }
  });

  return url.slice(0, -1); // Remove the trailing '&' or '?' if no params are added
};

export const fetcher = ({
  indexName = process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
  query = "",
  facets = ["*"],
  facetFilters = null,
  userToken = null,
  filters,
  hitsPerPage = 12,
  tagFilters = undefined,
  numericFilters,
  enablePersonalization = false,
  enableReRanking = false,
  personalizationImpact = undefined,
  optionalFilters = null,
  responseFields = ["hits"],
  attributesToRetrieve = [
    "id",
    "uid",
    "slug",
    "uri",
    "title",
    "image",
    "sectionHandle",
    "chefs",
    "accessLevel",
  ],
  attributesToHighlight = [],
  analytics = false,
  getRankingInfo = true,
  shuffleSeed = -1,
  entriesToShow = 12,
  uriOnly = false,
}) => {
  const enabledProxy = true;

  const index = searchClient.initIndex(indexName);

  const options = {
    analytics,
    enablePersonalization,
    enableReRanking,
    personalizationImpact,
    filters,
    facets,
    tagFilters,
    optionalFilters,
    facetFilters,
    getRankingInfo,
    hitsPerPage,
    maxValuesPerFacet: 100,
    responseFields,
    attributesToRetrieve,
    userToken,
    numericFilters,
    attributesToHighlight,
  };

  // We use the proxy to fetch data if the userToken is not provided, which at the moment, is every time
  if (!userToken && enabledProxy) {
    const queryString = new URLSearchParams({
      indexName,
      searchParams: JSON.stringify({ query, options }),
      shuffleSeed: JSON.stringify(shuffleSeed),
      entriesToShow: JSON.stringify(entriesToShow),
    }).toString();

    if (uriOnly) {
      // Respond with 200 and the API URL
      return `/api/search/proxy?${queryString}`;
    }

    // Use the proxy to fetch data
    return fetch(`/api/search/proxy?${queryString}`).then((response) =>
      response.json()
    );
  }

  // Otherwise we use the algolia client, but need to do the shuffle and slicing on the client
  return index.search(query, options);
};

export const trendingFetcher = ({
  indexName = process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
  responseFields = ["hits"],
  attributesToRetrieve = [
    "id",
    "uid",
    "slug",
    "uri",
    "title",
    "image",
    "sectionHandle",
    "chefs",
    "accessLevel",
  ],
  attributesToHighlight = [],
  facetName = undefined,
  facetValue = undefined,
  maxRecommendations = 12,
}) => {
  const enabledProxy = true;

  // Initialize the base queryParameters
  const queryParameters = {
    responseFields,
    attributesToRetrieve,
    attributesToHighlight,
  };

  const options = {
    indexName,
    maxRecommendations,
    queryParameters,
  };

  // Conditionally add facetName and facetValue if they are provided
  if (facetName !== undefined) {
    options.facetName = facetName;
  }
  if (facetValue !== undefined) {
    options.facetValue = facetValue;
  }

  if (enabledProxy) {
    const queryString = new URLSearchParams({
      searchParams: JSON.stringify(options),
    }).toString();

    return fetch(`/api/search/trending?${queryString}`).then((response) =>
      response.json()
    );
  }

  return recommendClient.getTrendingItems([options]);
};
