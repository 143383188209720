import { PaywallContext } from "contexts/PaywallContext";
import { useContext, useEffect } from "react";
import useSWR from "swr";

const usePayWall = () => {
  const context = useContext(PaywallContext);

  const { data, isLoading, error } = useSWR(
    `${process.env.NEXT_PUBLIC_CMS_URL}/paywall/variants.json`
  );

  useEffect(() => {
    if (data && data.variants) {
      context.setVariants(data.variants);
    } else if (error) {
      console.error("Failed to fetch paywall variants:", error);
      context.setVariants([]);
    }
  }, [data, error]); // Reacts to changes in `data` or `error`

  const setPaywall = ({
    isOpen,
    isClosable,
    variant = "default",
    title = "This recipe",
  }) => {
    context.setIsOpen(isOpen);
    context.setIsClosable(isClosable);
    context.setVariant(variant);
    context.setTitle(title);
  };

  const closePaywall = () => {
    context.setIsOpen(false);
  };

  return { setPaywall, closePaywall, data, isLoading };
};

export default usePayWall;
