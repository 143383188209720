import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import * as Popover from "@radix-ui/react-popover";
import clsx from "clsx";
import { useState } from "react";
import { useMenu } from "react-instantsearch";

const SearchOverlaySectionMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    <Popover.Root onOpenChange={setIsOpen} modal>
      <Popover.Trigger
        className={clsx(
          "h-component flex items-center space-x-2 border-r border-zinc-700 pr-4 text-zinc-50 focus:border-zinc-700"
        )}
      >
        <div className={clsx("whitespace-nowrap text-sm")}>I want...</div>
        <div>
          <Icon className={clsx("h-4 w-4")} />
        </div>
      </Popover.Trigger>
      <Popover.Anchor />
      <Popover.Portal className={clsx("z-50")}>
        <Popover.Content
          className={clsx("z-[51] w-80 rounded-2xl bg-zinc-900 text-white")}
          sideOffset={4}
          collisionPadding={24}
        >
          <Popover.Arrow
            className={clsx("fill-zinc-900")}
            width={15}
            height={7}
          />
          <div className={clsx("")}>
            <div className={clsx("p-6")}>
              <_Menu attribute="section.name" sortBy={["count:desc"]} />
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const _Menu = (props) => {
  const { items, refine } = useMenu(props);

  return (
    <div className={clsx("Menu")}>
      <ul className={clsx("", "flex flex-col space-y-2")}>
        {items.map((item) => {
          return (
            <li
              key={item.label}
              className={clsx(
                "group text-zinc-500 transition ease-out hover:text-zinc-50",
                {
                  selected: item.isRefined,
                }
              )}
            >
              <label
                className={clsx(
                  "flex cursor-pointer items-center font-body text-sm"
                )}
              >
                <input
                  type="checkbox"
                  checked={item.isRefined}
                  onChange={() => refine(item.value)}
                  className={clsx("hidden")}
                />
                <span className={clsx("font-body")}>{item.label}</span>
                <span
                  className={clsx(
                    "ml-auto flex h-5 items-center rounded-full bg-zinc-700 px-2 font-sans text-xs font-normal tabular-nums leading-none group-[.selected]:bg-zinc-200 group-[.selected]:text-zinc-950",
                    {}
                  )}
                >
                  {item.count}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchOverlaySectionMenu;
