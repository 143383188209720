export function getAlgoliaFilters(rail): string {
  const includeFilters = [];
  const excludeFilters = ["NOT partOfBatchMealPlan:true"]; // we'll never show batch recipes in rails

  for (const key in rail) {
    if (
      Array.isArray(rail[key]) &&
      (key.startsWith("include") || key.startsWith("exclude")) &&
      !key.endsWith("Recipes") // don't include includeRecipes or excludeRecipes here
    ) {
      rail[key].forEach((item) => {
        const filter = `${item.groupHandle}.title:"${item.title}"`;
        if (key.startsWith("exclude")) {
          excludeFilters.push(`NOT ${filter}`);
        } else {
          // For include filters
          includeFilters.push(filter);
        }
      });
    }
  }

  // We need to add any recipe exclusions to the excludeFilters
  if (rail.excludeRecipes && rail.excludeRecipes.length > 0) {
    rail.excludeRecipes.map((recipe) => {
      excludeFilters.push(`NOT objectID:${recipe.id}`);
    });
  }

  // Combine include filters using OR
  const includeFiltersString = includeFilters.join(" OR ");
  // Combine exclude filters using AND
  const excludeFiltersString = excludeFilters.join(" AND ");

  // Finally, combine include and exclude filters, if both exist
  let combinedFilters = "";
  if (includeFiltersString && excludeFiltersString) {
    combinedFilters = `(${includeFiltersString}) AND ${excludeFiltersString}`;
  } else if (includeFiltersString) {
    combinedFilters = includeFiltersString;
  } else if (excludeFiltersString) {
    combinedFilters = excludeFiltersString;
  }

  return combinedFilters;
}

export default getAlgoliaFilters;
