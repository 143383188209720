import AuthControl from "@/components/AuthControl";
import AuthMenu from "@/components/AuthMenu";
import GradientWrapper from "@/components/GradientWrapper";
import MenuToggle from "@/components/MenuToggle";
import MobLogotype from "@/components/MobLogotype";
import SearchBar from "@/components/SearchBar";
import SearchMobileFilterToggle from "@/components/SearchMobileFilterToggle";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { NotificationsContext } from "@/contexts/NotificationsContext";
import { useSearchContext } from "@/contexts/SearchContext";
import {
  joinButtonClicked,
  logoClicked,
  searchBarFocused,
  searchBarQuerySubmitted,
} from "@/gtmEvents/globalHeader";
import useCurrentUser from "@/hooks/useCurrentUser";
import pushToDataLayer from "@/lib/pushToDataLayer";
import clsx from "clsx";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef } from "react";

const Header = () => {
  const { showNotification } = useContext(NotificationsContext);

  const { isPinned, isTop } = useHeaderContext();

  const headerRef = useRef(null);

  const setHeaderHeight = () => {
    const headerHeight = headerRef.current.offsetHeight;
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  };

  useEffect(() => {
    setHeaderHeight();
    window.addEventListener("resize", setHeaderHeight);
    return () => {
      window.removeEventListener("resize", setHeaderHeight);
    };
  }, [headerRef]);

  return (
    <>
      <header
        ref={headerRef}
        className={clsx(
          "Header",
          "fixed left-0 right-0 top-0 z-50 w-screen transform-gpu border-white bg-black text-white print:static print:!bg-white print:text-black",
          {
            "lg:translate-y-0 print:!translate-y-0":
              (isTop || isPinned) && !showNotification,
            "translate-y-12 print:!translate-y-0": showNotification,
          }
        )}
      >
        <div
          className={clsx(
            "Header__container",
            "relative z-20 lg:h-20 2xl:h-24"
          )}
        >
          <_Desktop />
          <_Mobile />
        </div>
      </header>
    </>
  );
};

const _Desktop = observer(() => {
  const { isLoggedOut, isLoggedIn, isFetching, currentUser } = useCurrentUser();

  const router = useRouter();

  const isMobPlus = currentUser?.plan === 2;

  const { setIsDesktopOverlayOpen } = useSearchContext();

  const handleFocus = () => {
    setIsDesktopOverlayOpen(router.pathname !== "/search");

    // Send an event to GTM
    pushToDataLayer(searchBarFocused);
  };

  return (
    <div
      className={clsx(
        "hidden h-full grid-cols-12 items-center sm:gap-6 lg:grid"
      )}
    >
      <div
        className={clsx(
          "relative col-span-4 flex h-full items-center pl-12 print:!hidden"
        )}
      >
        <Link href="/">
          <a
            className={clsx("flex h-8 w-22 items-center active:scale-95")}
            aria-label="Mob"
            onClick={() => {
              pushToDataLayer(logoClicked);
            }}
          >
            <MobLogotype />
          </a>
        </Link>
        <div
          className={clsx(
            "ml-6 border-l border-l-zinc-500 pl-6 font-spatial text-xl font-normal"
          )}
        >
          Love cooking
        </div>
      </div>
      <div
        className={clsx(
          "col-span-8 flex h-full items-center justify-end space-x-6 pr-12 print:hidden"
        )}
      >
        <div className={clsx("flex-1 border-r border-r-zinc-500 pr-6")}>
          <_SearchBar
            handleFocus={handleFocus}
            inputId={"desktop-search-input"}
          />
        </div>
        <div className={clsx("flex space-x-4")}>
          {(isFetching || isLoggedOut) && (
            <div>
              <AuthControl />
            </div>
          )}
          {isLoggedOut && <_JoinCTA context="desktop" />}
          {isLoggedIn && (
            <>
              <div className="flex gap-2">
                <div>
                  <AuthMenu />
                </div>
                <div>
                  {!isMobPlus && (
                    <Link href="/premium#sign-up">
                      <a>
                        <GradientWrapper
                          theme="purple"
                          className={clsx("flex items-center")}
                        >
                          <div className={clsx("h-full p-[2px]")}>
                            <div
                              className={clsx(
                                "relative z-10 flex h-full items-center space-x-2 rounded-full px-4 text-zinc-50"
                              )}
                            >
                              <div
                                className={clsx(
                                  "whitespace-nowrap font-body text-sm"
                                )}
                              >
                                Upgrade
                              </div>
                            </div>
                          </div>
                        </GradientWrapper>
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            </>
          )}
          <div className={clsx("Header__menuToggle")}>
            <MenuToggle />
          </div>
        </div>
      </div>
    </div>
  );
});

const _Mobile = observer(() => {
  const { isLoggedOut, isLoggedIn, currentUser } = useCurrentUser();

  const router = useRouter();

  const isMobPlus = currentUser?.plan === 2;
  // Check if we're on the search page
  const isSearchIndex = router.pathname === "/search";

  const { setIsMobileOverlayOpen } = useSearchContext();

  const handleFocus = () => {
    // If we're at /search, don't open the overlay
    if (isSearchIndex) return;

    setIsMobileOverlayOpen(!isSearchIndex);

    // Send an event to GTM
    pushToDataLayer(searchBarFocused);
  };

  return (
    <div className={clsx("flex h-full flex-wrap lg:hidden")}>
      <div className={clsx("flex h-16 w-1/3 items-center justify-start px-6")}>
        <Link href="/">
          <a
            className={clsx("flex h-auto w-24 items-center active:scale-95")}
            aria-label="Mob"
          >
            <MobLogotype />
          </a>
        </Link>
      </div>

      <div className={clsx("flex h-16 w-2/3 items-center justify-end px-6")}>
        {isLoggedOut && (
          <div className={clsx("flex space-x-2")}>
            <_JoinCTA context="mobile" />
          </div>
        )}

        {isLoggedIn && (
          <>
            <div className="flex gap-2">
              <div>
                <AuthMenu />
              </div>
              {!isMobPlus && (
                <div>
                  <Link href="/premium#sign-up">
                    <a>
                      <GradientWrapper
                        theme="purple"
                        className={clsx("flex items-center")}
                      >
                        <div className={clsx("h-full p-[2px]")}>
                          <div
                            className={clsx(
                              "relative z-10 flex h-full items-center space-x-2 rounded-full px-4 text-zinc-50"
                            )}
                          >
                            <div
                              className={clsx(
                                "whitespace-nowrap font-body text-sm"
                              )}
                            >
                              Upgrade
                            </div>
                          </div>
                        </div>
                      </GradientWrapper>
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <MenuToggle />
        </div>
      </div>

      <div className={clsx("flex h-14 w-full items-start")}>
        <div
          className={clsx("flex-1 ", {
            "pl-6 pr-4": isSearchIndex,
            "px-6": !isSearchIndex,
          })}
        >
          <_SearchBar
            handleFocus={handleFocus}
            inputId={"mobile-search-input"}
          />
        </div>
        {isSearchIndex && (
          <div className={clsx("border-l border-l-zinc-700 pl-4 pr-6")}>
            <SearchMobileFilterToggle />
          </div>
        )}
      </div>
    </div>
  );
});

const _SearchBar = observer(({ handleFocus, inputId }) => {
  const router = useRouter();

  const { isLoggedIn, currentUser } = useCurrentUser();

  // Live search is available for logged in users, on the search page
  const searchAsYouType =
    router.pathname !== "/search" && isLoggedIn && currentUser.plan === 2;

  return (
    <SearchBar
      className={clsx("min-w-3xl")}
      searchAsYouType={searchAsYouType}
      onFocus={handleFocus}
      inputId={inputId}
      onSubmit={(query) => pushToDataLayer(searchBarQuerySubmitted, { query })}
    />
  );
});

const _JoinCTA = ({ context = "desktop" }) => {
  return (
    <Link href="/signup">
      <a
        className={clsx("Header__joinCta", "h-component")}
        onClick={() => pushToDataLayer(joinButtonClicked)}
      >
        <GradientWrapper theme="green" className={clsx("p-[2px]")}>
          <div
            className={clsx(
              "relative z-10 flex h-full items-center whitespace-nowrap rounded-full bg-black px-4 font-body text-sm font-medium transition ease-out hover:bg-black/0 hover:text-zinc-950"
            )}
          >
            Join the mob
          </div>
        </GradientWrapper>
      </a>
    </Link>
  );
};

export default Header;
