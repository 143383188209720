import dayjs from "dayjs";
import remove from "lodash/remove";
import { makeAutoObservable } from "mobx";
import shortId from "shortid";

export default class Timer {
  id = shortId.generate();

  recipeId;

  stepId;

  name;

  minutes;

  isActive = false;

  startedAt = dayjs();

  timeLeft = 0;

  constructor(store, { recipeId, stepId, name, minutes, isActive }) {
    makeAutoObservable(this);

    this.store = store;
    if (recipeId) this.recipeId = recipeId;
    if (stepId) this.stepId = stepId;
    if (name) this.name = name;
    if (minutes) this.minutes = minutes;
    if (isActive) this.isActive = isActive;

    // Kick the timer off
    this.measure();
  }

  adjustMinutes = (amount) => (this.minutes += amount);

  reset = () => {
    this.minutes = 0;
    this.startedAt = dayjs();
  };

  measure = () => {
    const targetTime = this.startedAt.add(this.minutes, "minutes");
    const difference = targetTime.diff(dayjs(), "seconds");

    this.timeLeft = difference;

    setTimeout(() => this.measure(), 250);
  };

  get isFinished() {
    return this.timeLeft <= 0;
  }

  delete = () => remove(this.store.timers, (t) => t.id === this.id);
}
