import clsx from "clsx";

const MobLogotype = () => {
  return (
    <svg
      viewBox="0 0 623 131"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      className={clsx("block h-full w-full")}
    >
      <path
        d="M617.555 64.042c-12.942-27.971-51.867-35.696-78.83-29.134-11.94 2.909-20.84 8.446-26.353 12.559.095-15.821.195-31.642.287-47.467h-71.125v128.142h71.433c.046-2.83.092-6.454.138-9.288 9.05 4.671 26.225 11.938 48.362 11.413 14.48-.342 40.267-.954 53.338-20.213 9.446-13.92 8.933-32.65 2.75-46.012m-87.188 32.287c-10.491 0-19-6.737-19-15.041 0-8.309 8.509-15.042 19-15.042 10.496 0 19 6.733 19 15.042 0 8.304-8.504 15.041-19 15.041M414.467 43.301c-8.767-3.858-18.375-6.57-27.863-7.858-25.545-3.47-51.254-3.246-76.854-.125-12.237 1.492-24.1 4.55-34.433 11.858-15.967 11.305-18.45 34.492-13.271 51.709 1.22 4.062 2.97 7.55 5.146 10.566 6.683 9.238 16.312 13.23 25.104 15.905 6.95 2.116 24.338 6.787 82.217 4.637 5.058-.187 13.333-1.287 24.129-3.337 19.383-3.675 34.942-12.992 37.767-35.975 2.579-21.03-3.25-39.155-21.942-47.38M348.634 97.21c-10.492 0-18.996-6.73-18.996-15.038s8.504-15.041 18.996-15.041c10.495 0 19.004 6.733 19.004 15.041 0 8.309-8.509 15.038-19.004 15.038M254.767 59.785c-1.092-11.525-8.246-19.312-19.392-23.175-16.912-5.85-32.887-3.825-39.791-2.541-16.4 3.041-28.925 10.041-37.13 15.837a54.857 54.857 0 0 0-6.908-6.696s-4.97-3.983-10.9-6.7c-12.642-5.8-30.996-3.533-37.892-2.62-8.266 1.091-20.012 3.42-33.52 8.87v-6.958H0v92.338h69.234v-5.946c.008-3.888.004-7.78 0-11.671v-10.97c.008-6.15.045-12.3.187-18.447.175-7.575 4.63-11.733 11.617-11.908 7.02-.175 10.766 3.28 11.766 11.054.284 2.2.242 4.45.246 6.675.004 2.625.013 5.246.025 7.867v20.758c-.02 2.725-.041 5.446-.091 8.167h.091v4.42h69.659v-5.291h.062c-.03-2.28-.046-4.563-.062-6.842V96.473c.041-5.383.112-10.763.25-16.142.17-6.825 4.354-10.646 10.825-11.096 6.854-.483 10.587 2.363 12.02 9.471.5 2.471.63 5.054.646 7.588.025 4.92.025 9.841.03 14.758v27.088h67.97l.292-68.355Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default MobLogotype;
