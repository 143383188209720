const sortByItems = [
  {
    label: "Latest",
    value: `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_latest_desc`,
  },
  {
    label: "Popular",
    value: `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_popularity_desc`,
  },
];

export default sortByItems;
