import ConfigureSearch from "@/components/ConfigureSearch";
import Layout from "@/components/Layout";
import Rewardful from "@/components/Rewardful";
import { AdContextProvider } from "@/contexts/AdContext";
import { CollectionsContextProvider } from "@/contexts/CollectionsContext";
import GeoPreferenceContextProvider from "@/contexts/GeoPreferenceContext";
import { HeaderContextProvider } from "@/contexts/HeaderContext";
import { MenuContextProvider } from "@/contexts/MenuContext";
import { MoodContextProvider } from "@/contexts/MoodContext";
import { NavigationContextProvider } from "@/contexts/NavigationContext";
import { NotificationsContextProvider } from "@/contexts/NotificationsContext";
import { PaywallContextProvider } from "@/contexts/PaywallContext";
import { PlannerContextProvider } from "@/contexts/PlannerContext";
import { ScreenSizeContextProvider } from "@/contexts/ScreenSizeContext";
import { SearchContextProvider } from "@/contexts/SearchContext";
import { SubscriptionContextProvider } from "@/contexts/SubscriptionContext";
import useApp from "@/hooks/useApp";
import useIsAuthPage from "@/hooks/useIsAuthPage";
import usePermission from "@/hooks/usePermission";
import { useStores } from "@/hooks/useStores";
import searchClient from "@/lib/algoliaSearchClient";
import colourways from "@/lib/colourways";
import "@/styles/globals.css";
import "@/styles/index.scss";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as Fathom from "fathom-client";
import { observer } from "mobx-react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { InstantSearch } from "react-instantsearch";
import { SWRConfig } from "swr";
import { YbugProvider } from "ybug-react";
import { Seomatic } from "react-seomatic";

function MyApp({ Component, pageProps }) {
  const {
    currentQuery,
    hideSiteUi = false, // TODO: remove this if no longer needed
    pageEntry = {},
    notifications = [],
    navigation = {},
    seomatic = {},
  } = pageProps;

  const hasIntercomPermission = usePermission("c:intercom");

  const { authStore } = useStores();

  const router = useRouter();

  const isAuthPage = useIsAuthPage();
  const isMobApp = useApp();

  // Initialize Fathom when the app loads
  useEffect(() => {
    // Only initializze fathom in production
    if (process.env.NODE_ENV !== "production") return;

    Fathom.load("VYBPFKNH", {
      url: "https://horse-thirtyeight.mob.co.uk/script.js",
      includedDomains: ["www.mob.co.uk"],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  // Handle Intercom

  useEffect(() => {
    // Don't load intercom in development, it always just errors
    if (process.env.NODE_ENV !== "production") return;

    if (!hasIntercomPermission) return;

    let userForIntercom = {
      api_base: "https://api-iam.intercom.io",
      app_id: "pkb1wr24",
    };

    // If they're logged in, add their details
    if (authStore.isLoggedIn) {
      userForIntercom = {
        ...userForIntercom,
        name: `${authStore.currentUser.firstName} ${authStore.currentUser.lastName}`,
        email: authStore.currentUser.email,
        created_at: parseInt(authStore.currentUser.dateCreated),
        user_id: authStore.currentUser.id,
        plan: authStore.currentUser.plan,
        user_hash: authStore.currentUser.hash,
      };
    }

    window.Intercom("boot", userForIntercom);

    // When route changes, update the user in Intercom
    const onRouteChangeComplete = () => {
      window.Intercom("update");
    };
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [authStore.isLoggedIn, hasIntercomPermission, authStore]);

  // Save the previous URL to aid with redirecting after logins
  useEffect(() => {
    const onRouteChangeStart = () => {
      // Never save the previous URL if the current URL is the login page
      if (isAuthPage) {
        return;
      }

      // if previousUrlOverride = true, don't update the previousUrl
      const previousUrlOverride = sessionStorage.getItem("previousUrlOverride");
      if (previousUrlOverride !== "true") {
        sessionStorage.setItem("previousUrl", router.asPath);
      }
      sessionStorage.setItem("previousUrlOverride", "false");
    };
    router.events.on("routeChangeStart", onRouteChangeStart);

    // Unassign event listener
    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [router]);

  /*
   * Page colourway
   */

  // If we have a pageEntry we might have a colourway
  const colourwayEntry = pageEntry?.colourway?.[0] || {};

  const colourway = colourways(colourwayEntry.colourwayHandle);

  /*
   * Algolia
   */

  // Use the layout defined by the page, or default to the standard layout
  const getLayout =
    Component.getLayout ||
    ((page) => (
      <Layout
        currentQuery={currentQuery}
        hideSiteUi={hideSiteUi}
        colourway={colourway}
        notifications={notifications}
        navigation={navigation}
      >
        {page}
      </Layout>
    ));

  const indexName = `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_latest_desc`;

  return (
    <SWRConfig
      value={{
        fetcher: (url) => fetch(url).then((r) => r.json()),
      }}
    >
      <Seomatic Head={Head} {...seomatic} />
      <Head>
        <meta
          name="viewport"
          content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
        ></meta>
        <script
          id="didomi"
          dangerouslySetInnerHTML={{
            __html: `
              window.gdprAppliesGlobally=true; ${
                isMobApp
                  ? `window.didomiConfig = {
            notice: {
                enable: false
            }
        };`
                  : ""
              };(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
  else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
  var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
  if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
  var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("b5ba5aa8-da64-4051-95b4-60daaf0348ee")})();
            `,
          }}
        />
      </Head>
      {hasIntercomPermission && (
        <Script
          id="intercom-script"
          dangerouslySetInnerHTML={{
            __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pkb1wr24';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();              `,
          }}
        />
      )}
      <Rewardful />
      <YbugProvider
        ybugId="4tdtt7rpsysnnq3r3cm8"
        settings={{
          hide_launcher: true,
        }}
      >
        <Tooltip.Provider delayDuration={0}>
          <InstantSearch
            searchClient={searchClient}
            future={{
              preserveSharedStateOnUnmount: true,
            }}
            indexName={indexName}
            insights={true}
          >
            <MoodContextProvider>
              <MenuContextProvider>
                <CollectionsContextProvider>
                  <GeoPreferenceContextProvider>
                    <PlannerContextProvider>
                      <SubscriptionContextProvider>
                        <NotificationsContextProvider>
                          <SearchContextProvider>
                            <HeaderContextProvider>
                              <NavigationContextProvider>
                                <PaywallContextProvider>
                                  <ScreenSizeContextProvider>
                                    <AdContextProvider>
                                      <ConfigureSearch />
                                      {getLayout(<Component {...pageProps} />)}
                                    </AdContextProvider>
                                  </ScreenSizeContextProvider>
                                </PaywallContextProvider>
                              </NavigationContextProvider>
                            </HeaderContextProvider>
                          </SearchContextProvider>
                        </NotificationsContextProvider>
                      </SubscriptionContextProvider>
                    </PlannerContextProvider>
                  </GeoPreferenceContextProvider>
                </CollectionsContextProvider>
              </MenuContextProvider>
            </MoodContextProvider>
          </InstantSearch>
        </Tooltip.Provider>
      </YbugProvider>
    </SWRConfig>
  );
}

export default observer(MyApp);
