import usePermission from "@/hooks/usePermission";
import Script from "next/script";
import * as React from "react";

type RewardfulType = never;

export const Rewardful: React.FunctionComponent<RewardfulType> = () => {
  const hasPermission = usePermission("c:rewardful-Gmnn264j");
  return hasPermission ? (
    <>
      <Script
        id="rewardfulInit"
        dangerouslySetInnerHTML={{
          __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
        }}
      />
      <Script async src="https://r.wdfl.co/rw.js" data-rewardful="bff007" />
    </>
  ) : (
    <></>
  );
};

export default Rewardful;
