import debounce from "lodash/debounce";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const ScreenSizeContext = createContext(null);

const breakpoints = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
  "3xl": 1728,
};

/*
	This context is used to provide the current window size, and map it to Tailwind's own breakpoints.
	We also provide a couple of helper functions to check if the current window size is at least a certain breakpoint,
	and if it's smaller than a certain breakpoint.
*/
export const ScreenSizeContextProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    breakpoint: null,
  });

  const calculateBreakpoint = (width) => {
    return (
      Object.keys(breakpoints)
        .reverse()
        .find((key) => width >= breakpoints[key]) || "xs"
    );
  };

  useEffect(() => {
    // Calculate initial breakpoint without waiting for resize event
    const initialBreakpoint = calculateBreakpoint(window.innerWidth);
    setWindowSize({ width: window.innerWidth, breakpoint: initialBreakpoint });

    const handleResize = debounce(() => {
      const width = window.innerWidth;
      if (width !== windowSize.width) {
        // Only update if width has changed
        const breakpoint = calculateBreakpoint(width);
        setWindowSize({ width, breakpoint });
      }
    }, 250); // 250ms debounce time

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);

  const isBreakpoint = useCallback(
    (bp) => windowSize.width >= breakpoints[bp],
    [windowSize.width]
  );

  const isMaxBreakpoint = useCallback(
    (bp) => windowSize.width < breakpoints[bp],
    [windowSize.width]
  );

  return (
    <ScreenSizeContext.Provider
      value={{ ...windowSize, isBreakpoint, isMaxBreakpoint }}
    >
      {children}
    </ScreenSizeContext.Provider>
  );
};

export const useScreenSizeContext = () => {
  const context = useContext(ScreenSizeContext);
  if (context === undefined) {
    throw new Error(
      "useScreenSizeContext must be used within a ScreenSizeContextProvider"
    );
  }
  return context;
};
