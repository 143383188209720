// apiClient.js
const API_BASE_URL = process.env.NEXT_PUBLIC_API_URL;

const apiClient = {
  fetch: async (endpoint, options = {}) => {
    const url = `${API_BASE_URL}${endpoint}`;

    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) throw new Error("API request failed");
      return response.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  get: (endpoint, userUid, queryParams = {}) => {
    let url = endpoint;
    const searchParams = new URLSearchParams(queryParams);
    const queryString = searchParams.toString();
    if (queryString) {
      url += `?${queryString}`;
    }
    return apiClient.fetch(url.toString(), {
      headers: { "x-user-uid": userUid },
    });
  },

  post: (endpoint, userUid, data) =>
    apiClient.fetch(endpoint, {
      method: "POST",
      headers: { "x-user-uid": userUid },
      body: JSON.stringify(data),
    }),

  put: (endpoint, userUid, data) =>
    apiClient.fetch(endpoint, {
      method: "PUT",
      headers: { "x-user-uid": userUid },
      body: JSON.stringify(data),
    }),

  delete: (endpoint, userUid) =>
    apiClient.fetch(endpoint, {
      method: "DELETE",
      headers: { "x-user-uid": userUid },
    }),
};

export default apiClient;
