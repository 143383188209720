import CountIndicator from "@/components/CountIndicator";
import { useMenuContext } from "@/contexts/MenuContext";
import { useSearchContext } from "@/contexts/SearchContext";
import useHasRefinements from "@/hooks/useHasRefinements";
import {
  AdjustmentsVerticalIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";

const SearchMobileFilterToggle = () => {
  const { setIsMobileFilterOverlayOpen, isMobileFilterOverlayOpen } =
    useSearchContext();

  const { setIsOpen } = useMenuContext();

  const { count } = useHasRefinements();

  const handleClick = () => {
    setIsMobileFilterOverlayOpen(!isMobileFilterOverlayOpen);
    setIsOpen(false);
  };

  const Icon = isMobileFilterOverlayOpen ? CheckIcon : AdjustmentsVerticalIcon;

  return (
    <button
      className={clsx(
        "h-component w-component relative flex items-center justify-center rounded-full bg-zinc-700 font-body text-sm text-zinc-50 transition ease-out hover:bg-zinc-600"
      )}
      onClick={handleClick}
      aria-label="Add Filter"
    >
      <Icon className={clsx("h-5 w-5")} />
      {count > 0 && <CountIndicator count={count} />}
    </button>
  );
};

export default SearchMobileFilterToggle;
